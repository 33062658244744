var gnavMyAccountCardCallback = gnavMyAccountCardCallback || {};

gnavMyAccountCardCallback.myAccountLiveChat = gnavMyAccountCardCallback.myAccountLiveChat || {};

(function ($) {
  gnavMyAccountCardCallback.myAccountLiveChat = function ($html, data, user) {
  // Dyanically intialize liveperson buttons in this layer
  // See: https://connect.liveperson.com/message/3476
  // Note: this should work, but doesn't
    var numLPButtons = $html.find('.lp-button a').length;

    if ($.isFunction(lpMTag.lpSetPage) && numLPButtons == 0) {
      var lpUnit = 'Clinique';

      lpMTagConfig.dynButton[lpMTagConfig.dynButton.length] = {
        'name': 'chat-CL UK CCC',
        'pid': 'lpchat-ccc',
        'afterStartPage': true
      };
      lpMTagConfig.dynButton[lpMTagConfig.dynButton.length] = {
        'name': 'chat-CL UK EAA',
        'pid': 'lpchat-eaa',
        'afterStartPage': true
      };
      lpMTagConfig.dynButton[lpMTagConfig.dynButton.length] = {
        'name': 'chat-' + lpUnit + '-video',
        'pid': 'lpbuttonvideo',
        'afterStartPage': true
      };
      lpMTag.lpSetPage();
    // console.log(lpMTagConfig);
    }
  };
})(jQuery);
